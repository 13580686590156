<template>
  <fw-layout wide back-to="/manage">
    <template #main-content>
      <PanelActivity class="m-5" api-endpoint="getActivityLogs" />
    </template>
  </fw-layout>
</template>

<script>
import PanelActivity from '@/fw-modules/fw-core-vue/ui/components/panels/PanelActivity'

export default {
  name: 'ManageActivity',
  components: {
    PanelActivity,
  },
  computed: {
    api() {
      return this.$store.state.api.base
    },
  },
}
</script>
